exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-care-js": () => import("./../../../src/pages/care.js" /* webpackChunkName: "component---src-pages-care-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-final-js": () => import("./../../../src/pages/final.js" /* webpackChunkName: "component---src-pages-final-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrate-js": () => import("./../../../src/pages/integrate.js" /* webpackChunkName: "component---src-pages-integrate-js" */),
  "component---src-pages-suppliers-js": () => import("./../../../src/pages/suppliers.js" /* webpackChunkName: "component---src-pages-suppliers-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-supplier-js": () => import("./../../../src/templates/supplier.js" /* webpackChunkName: "component---src-templates-supplier-js" */)
}

